
import { defineComponent, ref, onUnmounted, onMounted } from "vue";
// import { onBeforeRouteUpdate, useRoute } from 'vue-router'
// import type { RouteLocationNormalized } from 'vue-router'
import { useI18n } from "vue-i18n";
import RouteTabs from "@/views/Checking/spotChecking/components/RouteTabs.vue";
import { useRoute } from "vue-router";
import useEventbus from "@/hooks/checking/spotChecking/useEventbus";
import useCheckingPreparation from "@/hooks/checking/spotChecking/checkingPreparation/useCheckingPreparation";
import moment from 'moment'

export default defineComponent({
  name: "spotChecking",
  components: {
    RouteTabs,
  },
  setup() {
    const { customOn, customOff } = useEventbus();
    const total = ref<number>(0);
    customOn("on-count-change", (count: number) => {
      total.value = count;
    });
    onUnmounted(() => {
      customOff("on-count-change ");
    });
    const { t } = useI18n();
    // spotChecking
    const routeName = ref("spotCheckingVonder");
    const defaultBreadCrumbData = [
      { name: t("checking.checking"), path: "" },
      { name: t("checking.spotChecking"), path: "" },
    ];
    const breadCrumbData = ref(defaultBreadCrumbData);
    // 在其他tab页刷新页面时，重新获取checking preparation count
    const route = useRoute();
    const {
      getDealerList,
      getFlList,
      totalCount,
      query,
    } = useCheckingPreparation();
    onMounted(() => {
      if (route.name !== "checkingPreparation") {
        const startDate = moment()
          .month(moment().month())
          .startOf("month")
          .format("YYYY-MM-DD");

        const endDate = moment()
          .month(moment().month())
          .endOf("month")
          .format("YYYY-MM-DD");
        query.submissionDate = [startDate, endDate];
        const dealer = getDealerList();
        const fl = getFlList();
        Promise.all([dealer, fl]).then(() => {
          total.value = totalCount.value;
        });
      }
    });
    return {
      breadCrumbData,
      routeName,
      total,
    };
  },
});
