import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27422e88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container d-f flex-d-c" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_breadcrumb = _resolveComponent("breadcrumb")!
  const _component_route_tabs = _resolveComponent("route-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_breadcrumb, { dataSource: [] }),
    _createVNode(_component_route_tabs, { "route-name": _ctx.routeName }, {
      default: _withCtx(({ routername }) => [
        (routername === 'checkingPreparation')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(" + _toDisplayString(_ctx.total) + ")", 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["route-name"])
  ]))
}